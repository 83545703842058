import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { serviceApi } from '~/features';
import type { CreateCampaignDto, CreateCampaignModelView } from '~/types';

export const DUPLICATE_CAMPAIGN_MUTATION_KEY = 'DUPLICATE_CAMPAIGN_MUTATION';

async function duplicateCampaign(id?: string, body?: CreateCampaignDto) {
  return serviceApi.post<CreateCampaignModelView>(
    `/Campanha/${id}/duplicate`,
    body
  );
}

export function useDuplicateCampaign(
  onSuccess?: (
    data: AxiosResponse<CreateCampaignModelView, unknown>,
    variables: { id: string; body: CreateCampaignDto },
    context: unknown
  ) => unknown,
  onError?: (
    error: Error,
    variables: { id: string; body: CreateCampaignDto },
    context: unknown
  ) => unknown
) {
  return useMutation({
    mutationKey: [DUPLICATE_CAMPAIGN_MUTATION_KEY],
    mutationFn: async ({
      id,
      body,
    }: {
      id?: string;
      // eslint-disable-next-line @typescript-eslint/ban-types
      body: CreateCampaignDto | {};
    }) => duplicateCampaign(id, body as CreateCampaignDto),
    onSuccess,
    onError,
  });
}

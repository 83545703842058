import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import sanitize from 'sanitize-html';

import { serviceApi } from '~/features';
import type { CreateCampaignDto, CreateCampaignModelView } from '~/types';

export const CREATE_SPONSORED_PRODUCTS_MUTATION_KEY =
  'CREATE_SPONSORED_PRODUCTS_MUTATION';

async function createSponsoredProduct(body: CreateCampaignDto) {
  return serviceApi.post<CreateCampaignModelView>(
    '/CampanhaProdutosPatrocinados',
    {
      ...body,
      name: sanitize(body.name),
    }
  );
}

export function useCreateSponsoredProduct(
  onSuccess?: (
    data: AxiosResponse<CreateCampaignModelView, unknown>,
    variables: CreateCampaignDto,
    context: unknown
  ) => unknown,
  onError?: (
    error: Error,
    variables: CreateCampaignDto,
    context: unknown
  ) => unknown
) {
  return useMutation({
    mutationKey: [CREATE_SPONSORED_PRODUCTS_MUTATION_KEY],
    mutationFn: async (body: CreateCampaignDto) => createSponsoredProduct(body),
    onSuccess,
    onError,
  });
}

import { useQuery } from '@tanstack/react-query';

import { serviceApi } from '~/features';
import { clearParams } from '~/shared';
import { ProductsSoldDto } from '~/types';

export const GET_PRODUCTS_SOLD_KEY = 'GET_PRODUCTS_SOLD_QUERY';

function getProductsSold(params: ProductsSoldDto) {
  const clearedParams = clearParams(params) as ProductsSoldDto;
  return serviceApi.get<string>('/CalculoCampanha/produtos-vendidos', {
    params: clearedParams,
  });
}

export function useGetProductsSold(params: ProductsSoldDto) {
  return useQuery({
    queryKey: [GET_PRODUCTS_SOLD_KEY, params],
    queryFn: () => getProductsSold(params),
    select: data => data.data,
  });
}

import {
  LocalOfferOutlined,
  MouseOutlined,
  RemoveRedEyeOutlined,
  TrendingUpOutlined,
} from '@mui/icons-material';
import { Box } from '@mui/material';

import { AnunciadoIcon, Card, StyledCardGridContainer } from '~/shared';

import { useGetDashboard } from '../hooks/useGetDashboard';
import { useGetProductsSold } from '../hooks/useGetProductsSold';

export type DashCardsProps = {
  getDashboardsQuery: ReturnType<typeof useGetDashboard>;
  getProductsSoldQuery: ReturnType<typeof useGetProductsSold>;
};

export const DashCards = ({
  getDashboardsQuery,
  getProductsSoldQuery,
}: DashCardsProps) => {
  const { data: dashboardsData, isLoading: isLoadingDashboards } =
    getDashboardsQuery;
  const { data: productsSoldData, isLoading: isLoadingProductsSold } =
    getProductsSoldQuery;

  return (
    <Box>
      <StyledCardGridContainer>
        <>
          <Card
            title="Campanhas"
            iconBackgroundColor="#e0effc"
            iconColor="#0576F0"
            icon={<LocalOfferOutlined />}
            value={dashboardsData?.totalCampaign ?? '0'}
            isLoading={isLoadingDashboards}
          />

          <Card
            title="Total anunciado"
            iconBackgroundColor="#ebdff0"
            iconColor="#550084"
            icon={<AnunciadoIcon />}
            value={dashboardsData?.totalInvestment ?? '0'}
            isLoading={isLoadingDashboards}
          />

          <Card
            title="Produtos Vendidos"
            iconBackgroundColor="rgba(255,255,255,0.15)"
            iconColor="#FFFFFF"
            icon={<TrendingUpOutlined />}
            value={productsSoldData ?? '0'}
            isLoading={isLoadingProductsSold}
            sx={theme => ({ backgroundColor: theme.palette.primary.main })}
            titleColor="white"
          />

          <Card
            title="Impressões"
            iconBackgroundColor="#F5E7DE"
            iconColor="#C15702"
            icon={<RemoveRedEyeOutlined />}
            value={dashboardsData?.impressions ?? '0'}
            isLoading={isLoadingDashboards}
          />

          <Card
            title="Cliques"
            icon={<MouseOutlined />}
            value={dashboardsData?.clicks ?? '0'}
            isLoading={isLoadingDashboards}
          />

          <Card
            title="CTR"
            icon={<MouseOutlined />}
            value={dashboardsData?.ctr ?? '0%'}
            isLoading={isLoadingDashboards}
          />

          <Card
            title="CPC"
            icon={<MouseOutlined />}
            value={dashboardsData?.cpc ?? '0%'}
            isLoading={isLoadingDashboards}
          />

          <Card
            title="CPM"
            icon={<MouseOutlined />}
            value={dashboardsData?.cpm ?? '0%'}
            isLoading={isLoadingDashboards}
          />

          <Card
            title="CPI"
            icon={<MouseOutlined />}
            value={dashboardsData?.cpi ?? '0%'}
            isLoading={isLoadingDashboards}
          />
        </>
      </StyledCardGridContainer>
    </Box>
  );
};

import { type ComponentProps } from 'react';
import Image from 'next/image';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { IconButton, List } from '@mui/material';

import { ItemMenu } from './components/ItemMenu';
import { SignOut } from './components/SignOut';
import {
  StyledBoxContent,
  StyledMainDrawer,
  StyledMenuButtonBox,
} from './elements';
import { navList } from './listNavigation';

export function MainNavigation({
  toggleMenu,
  isMobile,

  ...props
}: Omit<ComponentProps<typeof StyledMainDrawer>, 'variant'> & {
  toggleMenu: () => void;
  isMobile: boolean;
  open: boolean;
}) {
  return (
    <StyledMainDrawer {...props} variant="permanent" data-testid="main-drawer">
      <StyledBoxContent open={props.open}>
        <StyledMenuButtonBox open={props.open}>
          <IconButton onClick={toggleMenu} data-testid="main-drawer-toggle">
            {props.open ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </StyledMenuButtonBox>
        <Image
          src="/static/logo_martins.png"
          alt="Martins Logo"
          width={100}
          height={100}
          className="logo-nav"
        />
        <List>
          {navList.map(item => (
            <ItemMenu key={item.href} item={item} open={props.open} />
          ))}
        </List>
      </StyledBoxContent>
      <SignOut open={props.open} />
    </StyledMainDrawer>
  );
}

export * from './elements';

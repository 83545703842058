import Link from 'next/link';
import { Box, Button } from '@mui/material';

import { Dialog, GreenCheck } from '~/shared';

export function ProductCreatedModal({ id }: { id: string }) {
  return (
    <Dialog
      title="Campanha criada com sucesso"
      withIcon={<GreenCheck size={70} />}
      open
      withCloseButton={false}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Link href={`/patrocinados/editar/${id}?step=periodo`} passHref>
          <Button
            sx={{ px: 3, py: 2 }}
            variant="contained"
            size="small"
            fullWidth
          >
            Continuar Editando
          </Button>
        </Link>

        <Link href="/patrocinados" passHref>
          <Button
            sx={{ px: 3, py: 2 }}
            size="small"
            fullWidth
            variant="outlined"
          >
            Ver lista de campanhas
          </Button>
        </Link>
      </Box>
    </Dialog>
  );
}

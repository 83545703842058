import {
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  StyledHeaderBoxTableCell,
  StyledHeaderTableCell,
  StyledTable,
} from '~/shared';
import SortIcon from '~/shared/icons/SortIcon';

import { useGetCampaigns } from '../../hooks';

import { CampaignRow } from './Row';

type SortConfigStates = {
  sortByOrderId: 'asc' | 'desc' | false;
  setSortByOrderId: (value: 'asc' | 'desc' | false) => void;
  sortByOrderCampaign: 'asc' | 'desc' | false;
  setSortByOrderCampaign: (value: 'asc' | 'desc' | false) => void;
  sortByOrderDevice: 'asc' | 'desc' | false;
  setSortByOrderDevice: (value: 'asc' | 'desc' | false) => void;
  sortByOrderInitial: 'asc' | 'desc' | false;
  setSortByOrderInitial: (value: 'asc' | 'desc' | false) => void;
  sortByOrderFinish: 'asc' | 'desc' | false;
  setSortByOrderFinish: (value: 'asc' | 'desc' | false) => void;
};
type CampaignListProps = {
  useGetCampaignsQuery: ReturnType<typeof useGetCampaigns>;
  sortConfig: SortConfigStates;
};

export function CampaignList({
  useGetCampaignsQuery,
  sortConfig,
}: CampaignListProps) {
  const { data, isLoading, isError, error } = useGetCampaignsQuery;

  const {
    sortByOrderId,
    setSortByOrderId,
    sortByOrderCampaign,
    setSortByOrderCampaign,
    sortByOrderDevice,
    setSortByOrderDevice,
    sortByOrderInitial,
    setSortByOrderInitial,
    sortByOrderFinish,
    setSortByOrderFinish,
  } = sortConfig;

  const resetSortStates = (
    ignoreSet: (value: 'asc' | 'desc' | false) => void
  ) => {
    const setters = {
      setSortByOrderId,
      setSortByOrderCampaign,
      setSortByOrderDevice,
      setSortByOrderInitial,
      setSortByOrderFinish,
    };

    Object.values(setters).forEach(setter => {
      if (setter !== ignoreSet) {
        setter(false);
      }
    });
  };

  const toggleSort = (
    current: 'asc' | 'desc' | false,
    setSort: (value: 'asc' | 'desc' | false) => void
  ) => {
    resetSortStates(setSort);
    switch (current) {
      case 'asc':
        setSort('desc');
        break;
      case 'desc':
        setSort(false);
        break;
      default:
        setSort('asc');
        break;
    }
  };

  return (
    <StyledTable
      aria-label="Tabela de Campanhas"
      sx={theme => ({
        [theme.breakpoints.up('lg')]: {
          tableLayout: 'fixed',
        },
      })}
    >
      <TableHead>
        <TableRow>
          <StyledHeaderTableCell sx={{ width: '60px' }} align="center">
            <StyledHeaderBoxTableCell>
              ID
              <SortIcon
                sortOrder={sortByOrderId}
                onClick={() => toggleSort(sortByOrderId, setSortByOrderId)}
              />
            </StyledHeaderBoxTableCell>
          </StyledHeaderTableCell>

          <StyledHeaderTableCell
            sx={{
              position: 'sticky',
              left: 0,
              minWidth: '220px',
              width: '220px',

              backgroundColor: 'background.paper',
            }}
          >
            Anunciante / Campanha
            <SortIcon
              sortOrder={sortByOrderCampaign}
              onClick={() =>
                toggleSort(sortByOrderCampaign, setSortByOrderCampaign)
              }
            />
          </StyledHeaderTableCell>

          <StyledHeaderTableCell align="center">
            <StyledHeaderBoxTableCell>
              Plataforma
              <SortIcon
                sortOrder={sortByOrderDevice}
                onClick={() =>
                  toggleSort(sortByOrderDevice, setSortByOrderDevice)
                }
              />
            </StyledHeaderBoxTableCell>
          </StyledHeaderTableCell>

          <StyledHeaderTableCell align="center">
            <StyledHeaderBoxTableCell>
              Início
              <SortIcon
                sortOrder={sortByOrderInitial}
                onClick={() =>
                  toggleSort(sortByOrderInitial, setSortByOrderInitial)
                }
              />
            </StyledHeaderBoxTableCell>
          </StyledHeaderTableCell>

          <StyledHeaderTableCell align="center">
            <StyledHeaderBoxTableCell>
              Término
              <SortIcon
                sortOrder={sortByOrderFinish}
                onClick={() =>
                  toggleSort(sortByOrderFinish, setSortByOrderFinish)
                }
              />
            </StyledHeaderBoxTableCell>
          </StyledHeaderTableCell>

          <StyledHeaderTableCell align="center">
            {' '}
            <StyledHeaderBoxTableCell>Status</StyledHeaderBoxTableCell>
          </StyledHeaderTableCell>
          <StyledHeaderTableCell align="center">
            <StyledHeaderBoxTableCell>Andamento</StyledHeaderBoxTableCell>
          </StyledHeaderTableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {isLoading && (
          <TableRow>
            <TableCell component="th" scope="row" colSpan={7} align="center">
              <CircularProgress size={15} /> Carregando
            </TableCell>
          </TableRow>
        )}

        {!isLoading && !isError && data?.list?.length === 0 && (
          <TableRow>
            <TableCell colSpan={7} align="center">
              Sem resultados
            </TableCell>
          </TableRow>
        )}

        {isError && (
          <TableRow>
            <TableCell
              sx={{ color: theme => theme.palette.error.light }}
              colSpan={7}
              align="center"
            >
              <Typography>Ocorreu um erro: {error.name}</Typography>
              <Typography>{error.message}</Typography>
            </TableCell>
          </TableRow>
        )}

        {!isLoading &&
          !isError &&
          data?.list?.map(campaign => {
            return <CampaignRow key={campaign.id} campaign={campaign} />;
          })}
      </TableBody>
    </StyledTable>
  );
}

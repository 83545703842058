import { useEffect } from 'react';
import Link from 'next/link';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, IconButton, List } from '@mui/material';

import { SubMenuItem } from '~/types/constants/menu';

import { StyledListItemButton, StyledListItemText } from '../elements';

import { SubItemMenuInside } from './SubItemMenuInside';

interface SubItemMenuProps {
  subItem: SubMenuItem;
  pathname: string;
  toggleSubmenu: (label: string) => void;
  selectedSubmenu?: string | null;
  open: boolean;
  clearSelectedSubmenu: () => void;
}

export function SubItemMenu({
  subItem,
  toggleSubmenu,
  clearSelectedSubmenu,
  selectedSubmenu,
  pathname,
  open,
}: SubItemMenuProps) {
  const { href, label, disabled, subMenuItems } = subItem;
  const hasSubMenu = subMenuItems && subMenuItems.length > 0;
  const isSubmenuOpen = subMenuItems?.some(sub => pathname.includes(sub.href));

  useEffect(() => {
    if (pathname === '/patrocinados') {
      toggleSubmenu(label);
    } else if (selectedSubmenu && selectedSubmenu !== label) {
      clearSelectedSubmenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    open && (
      <Box key={label}>
        <Link href={href} legacyBehavior>
          <StyledListItemButton
            className={pathname.includes(href) ? 'active' : ''}
            onClick={() => toggleSubmenu(label)}
            disabled={disabled}
          >
            <StyledListItemText inset>{label}</StyledListItemText>
            {hasSubMenu && (
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  toggleSubmenu(label);
                }}
                sx={{
                  marginLeft: 'auto',
                  color: 'inherit',
                  padding: 0,
                }}
              >
                {isSubmenuOpen ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            )}
          </StyledListItemButton>
        </Link>
        {hasSubMenu && (
          <Collapse in={isSubmenuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {subMenuItems.map(item => (
                <SubItemMenuInside
                  key={item.href}
                  subItem={item}
                  pathname={pathname}
                  toggleSubmenu={toggleSubmenu}
                />
              ))}
            </List>
          </Collapse>
        )}
      </Box>
    )
  );
}

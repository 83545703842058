'use client';

import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useSession } from 'next-auth/react';
import {
  AccountBalance,
  ControlPointDuplicate,
  EditOutlined,
  MouseOutlined,
  RemoveRedEyeOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Skeleton,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';

import {
  BannersList,
  StatusSelectBadge,
  useBreadcrumbs,
  useDownloadCampaignExcel,
  useDuplicateCampaign,
  useGetCampaign,
  useGetCampaignResultsDetailed,
  useGetCampaignResultsResumed,
} from '~/entities';
import {
  Card,
  ContentTitle,
  ExcelIcon,
  StyledCardGridContainer,
} from '~/shared';
import { CreateCampaignDto } from '~/types';
import roles from '~/types/constants/roles';

import { LoadingCampanhaView } from './loading';

export function CampanhaView({ id }: { id: string }) {
  const breadcrumbs = useBreadcrumbs();
  const session = useSession();
  const role = session?.data?.user?.role;
  const getCampaignQuery = useGetCampaign(id);
  const getCampaignResultsResumedQuery = useGetCampaignResultsResumed(id);
  const getCampaignResultsDetailedQuery = useGetCampaignResultsDetailed(id);
  const downloadCampaignExcelMutation = useDownloadCampaignExcel(data => {
    const nowFormatted = format(new Date(), 'dd-MM-yyyy_HH-mm');
    const filename = `Campanha - ${getCampaignQuery.data?.name ?? 'Sem Nome'} - ${nowFormatted}.xlsx`;
    const blob = new Blob([data.data], { type: data.headers['content-type'] });
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = filename;
    a.click();
  });
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isDuplicationSuccess, setIsDuplicationSuccess] = useState(false);
  const [duplicationError, setDuplicationError] = useState<string | null>(null);

  const contentTitle = getCampaignQuery.data?.name ?? 'Carregando';

  const hasInitialized = useRef(false);

  const duplicateCampaign = useDuplicateCampaign(error => {
    console.error(error);
  });

  useEffect(() => {
    if (!hasInitialized.current) {
      breadcrumbs.setPaths([
        { label: 'Campanhas', path: '/campanhas' },
        { label: contentTitle, path: `/campanhas/${id}` },
      ]);
      getCampaignQuery.refetch();
      hasInitialized.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs, contentTitle, id]);

  function handleExportExcel() {
    downloadCampaignExcelMutation.mutate(id);
  }

  function handleDuplicateCampaign(campaignId?: string) {
    if (getCampaignQuery.data) {
      const campaignBody: CreateCampaignDto = {
        name: '',
        totalInvestment: 0,
        isApp: false,
        isWeb: false,
        advertiserId: '',
      };

      setIsDuplicating(true);
      setDuplicationError(null);
      setIsDuplicationSuccess(false);

      duplicateCampaign.mutate(
        {
          id: campaignId!,
          body: campaignBody,
        },
        {
          onSuccess: () => {
            setIsDuplicating(false);
            setIsDuplicationSuccess(true);
          },
          onError: (error: Error) => {
            setIsDuplicating(false);
            setDuplicationError(error.message);
          },
        }
      );
    }
  }

  return (
    <>
      <ContentTitle
        title={contentTitle}
        isFetching={
          getCampaignQuery.isFetching ||
          getCampaignResultsResumedQuery.isFetching ||
          getCampaignResultsDetailedQuery.isFetching
        }
        actions={
          <>
            {getCampaignQuery.isLoading && (
              <>
                <Skeleton
                  variant="rectangular"
                  width={120}
                  height={32}
                  sx={{ mr: 1 }}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  width={120}
                  height={32}
                  sx={{ mr: 1 }}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  width={200}
                  height={32}
                  animation="wave"
                />
              </>
            )}
            {!getCampaignQuery.isLoading && (
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  startIcon={
                    downloadCampaignExcelMutation.isPending ? (
                      <CircularProgress size={15} />
                    ) : (
                      <ExcelIcon size={20} />
                    )
                  }
                  variant="outlined"
                  sx={{
                    px: 2,
                    py: 1,
                    textTransform: 'none',
                  }}
                  onClick={handleExportExcel}
                  disabled={downloadCampaignExcelMutation.isPending}
                >
                  Exportar
                </Button>

                {role !== roles[0] && (
                  <Link
                    href={`/campanhas/editar/${getCampaignQuery.data?.id}`}
                    passHref
                  >
                    <Button
                      sx={{ px: 2, py: 1, textTransform: 'none' }}
                      startIcon={<EditOutlined />}
                      variant="outlined"
                    >
                      Editar
                    </Button>
                  </Link>
                )}
                <Button
                  startIcon={<ControlPointDuplicate />}
                  variant="outlined"
                  sx={{
                    px: 2,
                    py: 1,
                    textTransform: 'none',
                  }}
                  onClick={() =>
                    handleDuplicateCampaign(getCampaignQuery.data?.id)
                  }
                >
                  Duplicar
                </Button>
                {getCampaignQuery.data?.status && (
                  <StatusSelectBadge
                    campaignId={getCampaignQuery.data?.id}
                    campaignStatus={getCampaignQuery.data?.status}
                  />
                )}
              </Box>
            )}
          </>
        }
      />

      <Box>
        <StyledCardGridContainer>
          {!getCampaignQuery.isLoading && (
            <>
              <Card
                title="Total Anunciado"
                iconBackgroundColor="#E7DDEC"
                iconColor="#550182"
                icon={<AccountBalance />}
                value={`R$ ${getCampaignQuery.data?.totalInvestment?.toLocaleString('pt-BR')}`}
                // variation={7}
                // variationText="Últimos 30 dias"
              />

              <Card
                title="Impressões"
                iconBackgroundColor="#F5E7DE"
                iconColor="#C15702"
                icon={<RemoveRedEyeOutlined />}
                value={(
                  getCampaignResultsResumedQuery.data?.impressions ?? 0
                ).toLocaleString('pt-BR')}
                // variation={-8}
                // variationText="Últimos 7 dias"
              />

              <Card
                title="Cliques"
                icon={<MouseOutlined />}
                value={(
                  getCampaignResultsResumedQuery.data?.clicks ?? 0
                ).toLocaleString('pt-BR')}
                // variation={19}
                // variationText="Últimos 30 dias"
              />

              <Card
                title="CTR"
                icon={<MouseOutlined />}
                value={getCampaignResultsResumedQuery.data?.ctr ?? '0%'}
                // variation={-3}
                // variationText="Últimos 30 dias"
              />

              <Card
                title={['CPM', 'CPC']}
                value={[
                  getCampaignResultsResumedQuery.data?.cpm ?? 'R$ 0,00',
                  getCampaignResultsResumedQuery.data?.cpc ?? 'R$ 0,00',
                ]}
                // variation={-3}
                // variationText="Últimos 30 dias"
              />

              <Card
                title="CPI"
                icon={<MouseOutlined />}
                value={getCampaignResultsResumedQuery.data?.cpi ?? 'R$ 0,00'}
                // variation={-3}
                // variationText="Últimos 30 dias"
              />

              <BannersList
                getCampaignResultsDetailedQuery={
                  getCampaignResultsDetailedQuery
                }
              />
            </>
          )}

          {getCampaignQuery.isLoading && <LoadingCampanhaView />}
        </StyledCardGridContainer>
      </Box>

      <Dialog
        open={isDuplicationSuccess}
        onClose={() => setIsDuplicationSuccess(false)}
      >
        <Typography
          sx={{
            m: 1,
            color: 'primary.black',
            textAlign: 'center',
            fontSize: 28,
          }}
          variant="h4"
        >
          Campanha duplicada com sucesso!
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 4, gap: 2 }}>
          <Button
            size="large"
            variant="contained"
            sx={{ px: 3, py: 1.5 }}
            onClick={() => {
              setIsDuplicationSuccess(false);
            }}
          >
            Sair
          </Button>
        </Box>
      </Dialog>

      <Dialog
        open={!!duplicationError}
        onClose={() => setDuplicationError(null)}
      >
        <Typography
          sx={{
            m: 1,
            color: 'primary.black',
            textAlign: 'center',
            fontSize: 28,
          }}
          variant="h4"
        >
          {' '}
          Erro ao duplicar campanha
        </Typography>

        <Button onClick={() => setDuplicationError(null)} variant="contained">
          Fechar
        </Button>
      </Dialog>

      <Dialog open={isDuplicating}>
        <Box p={4} display="flex" flexDirection="column" alignItems="center">
          <CircularProgress size={50} sx={{ mb: 2 }} />
          <Typography variant="h6">Duplicando campanha...</Typography>
        </Box>
      </Dialog>
    </>
  );
}

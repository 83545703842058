'use client';

import type { ReactNode } from 'react';
import { useRouter } from 'next/navigation';
import { Box, Button } from '@mui/material';

// import { useState } from 'react';
import { StyledCampaignForm } from '../../../Campaign/components/elements';
import { useGetCampaign, useUpdateCampaign } from '../../../Campaign/hooks';

import { RowSegments } from './RowSegments';
import { RowTime } from './RowTime';

export function SponsoredProductsFormStepSegmentation({
  continueAction,
  cancelActionNode,
  campaign,
  updateCampaignQuery,
}: {
  continueAction: () => void;
  cancelActionNode: ReactNode;
  campaign?: ReturnType<typeof useGetCampaign>['data'];
  updateCampaignQuery: ReturnType<typeof useUpdateCampaign>;
}) {
  const router = useRouter();

  if (!campaign) return null;

  return (
    <StyledCampaignForm>
      <RowTime campaign={campaign} updateCampaignQuery={updateCampaignQuery} />
      <RowSegments
        campaign={campaign}
        updateCampaignQuery={updateCampaignQuery}
      />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        {cancelActionNode}
        <Box sx={{ display: 'flex', gap: 2 }}>
          {campaign.status === 'DRAFT' && (
            <Button
              variant="outlined"
              color="primary"
              size="large"
              sx={{ px: 3, py: 1.5 }}
              onClick={() => {
                updateCampaignQuery.mutate({
                  id: campaign.id,
                  status: 'DRAFT',
                });
                router.push(`/campanhas/${campaign.id}`);
              }}
            >
              Salvar Rascunho
            </Button>
          )}
          {campaign?.status === 'ACTIVE' && (
            <Button
              variant="outlined"
              color="primary"
              size="large"
              sx={{ px: 3, py: 1.5 }}
              onClick={() => {
                if (!campaign) return;
                router.push(`/campanhas/${campaign.id}`);
              }}
            >
              Sair
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ px: 3, py: 1.5 }}
            onClick={() => continueAction()}
          >
            Continuar
          </Button>
        </Box>
      </Box>
    </StyledCampaignForm>
  );
}

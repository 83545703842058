import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import sanitize from 'sanitize-html';

import { serviceApi } from '~/features';
import type { UpdateCampaignDto, UpdateCampaignModelView } from '~/types';

export const PATCH_SPONSORED_CAMPAIGN_MUTATION_KEY =
  'PATCH_SPONSORED_CAMPAIGN_MUTATION';

async function updateSponsoredCampaign(body: UpdateCampaignDto) {
  return serviceApi.patch<UpdateCampaignModelView>(
    '/CampanhaProdutosPatrocinados',
    { ...body, ...(body.name ? { name: sanitize(body.name) } : {}) },
    { headers: { 'Content-Type': 'application/json' } }
  );
}

export function useUpdateSponsoredCampaign(
  onSuccess?: (
    data: AxiosResponse<UpdateCampaignModelView, unknown>,
    variables: UpdateCampaignDto,
    context: unknown
  ) => unknown,
  onError?: (
    error: Error,
    variables: UpdateCampaignDto,
    context: unknown
  ) => unknown
) {
  return useMutation({
    mutationKey: [PATCH_SPONSORED_CAMPAIGN_MUTATION_KEY],
    mutationFn: async (body: UpdateCampaignDto) =>
      updateSponsoredCampaign(body),
    onSuccess,
    onError,
  });
}

export type UseUpdateSponsoredCampaignReturn = ReturnType<
  typeof useUpdateSponsoredCampaign
>;

import React from 'react';
import Link from 'next/link';
import { Box, ListItemIcon } from '@mui/material';

import { SubMenuItem } from '~/types/constants/menu';

import { StyledListItemButton, StyledListItemText } from '../elements';

interface SubItemMenuInsideProps {
  subItem: SubMenuItem;
  pathname: string;
  toggleSubmenu: (label: string) => void;
}

export function SubItemMenuInside({
  subItem,
  pathname,
  toggleSubmenu,
}: SubItemMenuInsideProps) {
  const { href, label, disabled } = subItem;

  return (
    <Box key={label} sx={{ marginLeft: 1 }}>
      <Link href={href} legacyBehavior key={label}>
        <StyledListItemButton
          className={pathname === href ? 'active' : ''}
          onClick={() => toggleSubmenu(label)}
          disabled={disabled}
        >
          <StyledListItemText inset>{label}</StyledListItemText>
          <ListItemIcon
            sx={{
              minWidth: 'inherit',
              color: 'currentcolor',
              textAlign: 'right',
            }}
          />
        </StyledListItemButton>
      </Link>
    </Box>
  );
}
export default SubItemMenuInside;

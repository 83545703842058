'use client';

import { type ReactNode } from 'react';
import { Box, Button } from '@mui/material';

import {
  StyledCampaignForm,
  UseUpdateCampaignReturn,
} from '~/entities/Campaign';
import type { BannersKey, CampaignBanner, CampaignStatus } from '~/types';

export function SponsoredProductFormStepBanners({
  campaignId,
  campaignStatus,
  continueAction,
  cancelActionNode,
  campaignBanners,
  bannerType,
  bannerKeys,
  continueLabel,
  updateCampaignQuery,
  linkUrl,
  activeLink,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...restProps
}: {
  campaignId: string;
  campaignStatus?: `${CampaignStatus}`;
  continueAction: () => void;
  cancelActionNode: ReactNode;
  campaignBanners?: CampaignBanner[];
  bannerType: 'web' | 'app';
  bannerKeys?: BannersKey[];
  continueLabel: string;
  updateCampaignQuery: UseUpdateCampaignReturn;
  linkUrl?: string | undefined | null;
  activeLink?: boolean;
}) {
  return (
    <StyledCampaignForm>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        {cancelActionNode}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ px: 3, py: 1.5 }}
            onClick={() => continueAction()}
          >
            {continueLabel}
          </Button>
        </Box>
      </Box>
    </StyledCampaignForm>
  );
}

'use client';

import React, { type SyntheticEvent, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import Link from 'next/link';
import { redirect, useRouter, useSearchParams } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Skeleton,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import {
  GET_CAMPAIGN_RESULTS_DETAILED_QUERY_KEY,
  GET_CAMPAIGN_RESULTS_RESUMED_QUERY_KEY,
  ProductList,
  SponsoredProductsFormStepInformation,
  SponsoredProductsFormStepSegmentation,
  StatusSelectBadge,
  StyledCampaignFormRow,
  StyledCampaignFormSubtitle,
  StyledTabItem,
  useBreadcrumbs,
  useGetBannersKeys,
  useGetProduct,
  useGetProductsList,
  useUpdateSponsoredCampaign,
} from '~/entities';
import { SponsoredProductFormStepBanners } from '~/entities/SponsoredProducts/forms/StepBanners/StepBanners';
import {
  ContentTitle,
  Dialog,
  Pagination,
  SavingStatusBadge,
  SearchBox,
  StyledContainer,
  StyledListProducts,
  StyledTableContainer,
} from '~/shared';
import { DownloadIcon } from '~/shared/icons/DownloadIcon';
import { CampaignListDTO } from '~/types';
import roles from '~/types/constants/roles';

import { StatusSelect } from './components/StatusSelect';

const PRODUCTS_PAGE_SIZE = 15;

export function FormPatrocinadosView({
  id,
  searchParams,
}: {
  id?: string;
  searchParams?: { [key: string]: string | string[] | undefined };
}) {
  const router = useRouter();
  const session = useSession();

  const breadcrumbs = useBreadcrumbs();

  const getBannerKeysQuery = useGetBannersKeys();
  const queryClient = useQueryClient();

  const stepParam =
    searchParams?.step && searchParams?.step !== ''
      ? (searchParams?.step as string)
      : 'dados';
  const role = session?.data?.user?.role;
  const [step, setStep] = useState(stepParam);
  const [isAppCampaign, setIsAppCampaign] = useState(false);
  const [isWebCampaign, setIsWebCampaign] = useState(false);
  const [stepOneFormError, setStepOneFormError] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isPublishingSuccess, setIsPublishingSuccess] = useState(false);

  const searchParamsUrl = useSearchParams();
  const advertiserSearch = searchParamsUrl.get('advertiserUrl') ?? '';
  const [campaignQuery, setCampaignQuery] = useState<string>(
    `${advertiserSearch}`
  );
  const [score, setScore] = useState('');

  if (!id) redirect('/patrocinados');

  const getCampaignQuery = useGetProduct(id);

  const updateCampaignQuery = useUpdateSponsoredCampaign(() => {
    getCampaignQuery.refetch();
    queryClient.removeQueries({
      queryKey: [GET_CAMPAIGN_RESULTS_DETAILED_QUERY_KEY, id],
    });
    queryClient.removeQueries({
      queryKey: [GET_CAMPAIGN_RESULTS_RESUMED_QUERY_KEY, id],
    });
  });

  useEffect(() => {
    if (role === roles[0]) router.push('/patrocinados');
  }, [role, router]);

  useEffect(() => {
    if (getCampaignQuery.isSuccess) {
      const campaign = getCampaignQuery.data;
      if (campaign.isApp) setIsAppCampaign(true);
      if (campaign.isWeb) setIsWebCampaign(true);

      if (!campaign.isApp && campaign.isWeb && stepParam === 'app') {
        setStep('produtos');
        router.push(`/patrocinados/editar/${id}?step=produtos`);
      }

      if (!campaign.isWeb && campaign.isApp && stepParam === 'web') {
        setStep('produtos');
        router.push(`/patrocinados/editar/${id}?step=produtos`);
      }

      if (!campaign.isWeb && !campaign.isApp) {
        setStep('dados');
        router.push(`/patrocinados/editar/${id}?step=dados`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCampaignQuery.isSuccess]);

  function handleStepChange(_: SyntheticEvent, newValue: string) {
    setStep(newValue);
    router.push(`/patrocinados/editar/${id}?step=${newValue}`);
    window.scrollTo({ top: 0 });
  }

  const contentTitle = getCampaignQuery.isLoading
    ? 'Carregando...'
    : getCampaignQuery.data?.name ?? '';

  function handleContinueActionFirstStep() {
    if (!stepOneFormError) {
      setStep('periodo');
      router.push(`/patrocinados/editar/${id}?step=periodo`);
      window.scrollTo({ top: 0 });
    }
  }

  useEffect(() => {
    breadcrumbs.setPaths([
      { label: 'Patrocinados', path: '/patrocinados' },
      { label: contentTitle, path: `/patrocinados/${id}` },
      { label: 'Editar', path: `/patrocinados/editar/${id}` },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentTitle]);

  const [productsFilter, setProductsFilter] = useState<CampaignListDTO>({
    query: campaignQuery,
    count: PRODUCTS_PAGE_SIZE,
    page: 1,
  });

  const useGetCampaignsQuery = useGetProductsList(productsFilter);

  const total = useGetCampaignsQuery.data?.total ?? 0;
  const totalPages = Math.ceil(total / PRODUCTS_PAGE_SIZE);

  useDebounce(
    () => {
      setProductsFilter(state => ({
        ...state,
        query: campaignQuery,
        page: 1,
      }));
    },
    500,
    [campaignQuery]
  );

  useDebounce(
    () => {
      setProductsFilter(state => ({
        ...state,
        score: score === '' ? undefined : score,
        page: 1,
      }));
    },
    1,
    [score]
  );

  function nextPageHandler() {
    if (productsFilter.page < totalPages) {
      setProductsFilter(state => ({
        ...state,
        page: state.page + 1,
      }));
    }
  }

  function prevPageHandler() {
    if (productsFilter.page > 1) {
      setProductsFilter(state => ({
        ...state,
        page: state.page - 1,
      }));
    }
  }

  const [sortByOrderId, setSortByOrderId] = useState<'asc' | 'desc' | false>(
    false
  );
  const [sortByOrderCampaign, setSortByOrderCampaign] = useState<
    'asc' | 'desc' | false
  >(false);
  const [sortByOrderDevice, setSortByOrderDevice] = useState<
    'asc' | 'desc' | false
  >(false);
  const [sortByOrderInitial, setSortByOrderInitial] = useState<
    'asc' | 'desc' | false
  >(false);
  const [sortByOrderFinish, setSortByOrderFinish] = useState<
    'asc' | 'desc' | false
  >(false);

  const sortConfig = {
    sortByOrderId,
    setSortByOrderId,
    sortByOrderCampaign,
    setSortByOrderCampaign,
    sortByOrderDevice,
    setSortByOrderDevice,
    sortByOrderInitial,
    setSortByOrderInitial,
    sortByOrderFinish,
    setSortByOrderFinish,
  };

  const renderCampaignTabPanel = (bannerType: 'app' | 'web') => {
    const isWeb = bannerType === 'web';

    return (
      <TabPanel
        sx={theme => ({ [theme.breakpoints.down('md')]: { p: 0 } })}
        value="produtos"
      >
        <Box sx={{ paddingBottom: 2 }}>
          <StyledCampaignFormSubtitle>
            Lista de Produtos
          </StyledCampaignFormSubtitle>
        </Box>
        <StyledCampaignFormRow
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            size="large"
            variant="contained"
            sx={{ px: 3, py: 1.5 }}
            onClick={() => setIsPublishingSuccess(false)}
          >
            Enviar Lista (.xls)
          </Button>

          <Link href="campanhas" passHref>
            <Box
              component="a"
              sx={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                cursor: 'pointer',
                color: '#2B52DD',
              }}
            >
              <DownloadIcon color="#2B52DD" size={14} />
              <Typography
                sx={{
                  fontWeight: 500,
                  textDecoration: 'underline',
                  color: '#2B52DD',
                  marginLeft: 1,
                }}
              >
                Baixar lista padrão (.xls)
              </Typography>
            </Box>
          </Link>
        </StyledCampaignFormRow>
        <StyledListProducts>
          <Box
            sx={theme => ({
              display: 'flex',
              gap: 2,
              mb: 4,
              flexDirection: 'column',
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                '> *:first-of-type': {
                  flexBasis: '80%',
                  flexShrink: 0,
                  flexGrow: 0,
                },
                '> *:not(:first-of-type)': {
                  flexBasis: '20%',
                  flexShrink: 1,
                  flexGrow: 1,
                },
              },
            })}
          >
            <SearchBox
              label="Procurar por SKU, Nome do produto ou palavra-chave"
              onChange={setCampaignQuery}
              value={campaignQuery}
              fullWidth
            />
            <StatusSelect
              fullWidth
              growAtLg
              selectedStatus={score}
              setter={setScore}
            />
          </Box>

          <StyledTableContainer>
            <ProductList
              useGetCampaignsQuery={useGetCampaignsQuery}
              sortConfig={sortConfig}
            />
          </StyledTableContainer>

          <Pagination
            disabled={
              totalPages === 1 || useGetCampaignsQuery.isFetching || total === 0
            }
            page={productsFilter.page}
            count={totalPages}
            nextPage={nextPageHandler}
            prevPage={prevPageHandler}
            onChange={(_, newPage) =>
              setProductsFilter(state => ({ ...state, page: newPage }))
            }
          />
        </StyledListProducts>

        <SponsoredProductFormStepBanners
          campaignId={id}
          bannerType={bannerType}
          updateCampaignQuery={updateCampaignQuery}
          campaignStatus={getCampaignQuery.data?.status}
          bannerKeys={
            isWeb ? getBannerKeysQuery.data?.web : getBannerKeysQuery.data?.app
          }
          campaignBanners={
            isWeb
              ? getCampaignQuery.data?.webBanners
              : getCampaignQuery.data?.appBanners
          }
          linkUrl={
            isWeb
              ? getCampaignQuery.data?.webLink
              : getCampaignQuery.data?.appLink
          }
          activeLink={
            isWeb
              ? getCampaignQuery.data?.webLinkActive
              : getCampaignQuery.data?.appLinkActive
          }
          continueLabel={
            getCampaignQuery.data?.status === 'DRAFT'
              ? 'Publicar Campanha'
              : 'Salvar e Sair'
          }
          continueAction={() => {
            if (getCampaignQuery.data?.status === 'DRAFT') {
              setIsPublishing(true);
            } else {
              router.push(`/patrocinados/${id}`);
            }
          }}
          cancelActionNode={
            <Button
              size="large"
              onClick={() => {
                setStep('periodo');
                router.push(`/patrocinados/editar/${id}?step=periodo`);
              }}
              sx={{ px: 3, py: 1.5 }}
            >
              Voltar
            </Button>
          }
        />
      </TabPanel>
    );
  };

  return (
    <>
      <ContentTitle
        title={contentTitle}
        returnLink={`/patrocinados/${id}`}
        actions={
          getCampaignQuery.isSuccess && (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <SavingStatusBadge
                isLoading={getCampaignQuery.isLoading}
                isSaving={updateCampaignQuery.isPending}
                lastSaveDate={getCampaignQuery.data.updatedAt}
                createdAt={getCampaignQuery.data.createdAt}
              />
              {getCampaignQuery.data?.status && (
                <StatusSelectBadge
                  campaignId={getCampaignQuery.data?.id}
                  campaignStatus={getCampaignQuery.data?.status}
                />
              )}
            </Box>
          )
        }
      />

      <StyledContainer>
        <TabContext value={step}>
          <TabList
            sx={{
              '.MuiTabs-flexContainer': {
                flexDirection: 'row',
                gap: 2,
              },
            }}
            onChange={handleStepChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <StyledTabItem
              label="Dados de campanha"
              value="dados"
              disabled={getCampaignQuery.isLoading}
            />
            <StyledTabItem
              label="Período e Segmentação"
              value="periodo"
              disabled={getCampaignQuery.isLoading}
            />
            <StyledTabItem
              label="Lista de Produtos"
              value="produtos"
              disabled={
                getCampaignQuery.isLoading || (!isAppCampaign && !isWebCampaign)
              }
            />
          </TabList>
          <TabPanel
            sx={theme => ({ [theme.breakpoints.down('md')]: { p: 0 } })}
            value="dados"
          >
            {getCampaignQuery.isSuccess && (
              <SponsoredProductsFormStepInformation
                campaignId={id}
                campaign={getCampaignQuery.data}
                updateCampaignQuery={updateCampaignQuery}
                getCampaignQuery={getCampaignQuery}
                setStepOneFormError={setStepOneFormError}
                setIsWebCampaign={setIsWebCampaign}
                setIsAppCampaign={setIsAppCampaign}
                continueAction={() => handleContinueActionFirstStep()}
                cancelActionNode={
                  <Link href={`/patrocinados/${id}`} passHref>
                    <Button size="large" sx={{ px: 3, py: 1.5 }}>
                      Sair
                    </Button>
                  </Link>
                }
              />
            )}
            {getCampaignQuery.isLoading && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                  mt: 4,
                }}
              >
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height="56px"
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height="56px"
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height="56px"
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{ width: '400px', height: '56px' }}
                />
              </Box>
            )}
          </TabPanel>
          <TabPanel
            sx={theme => ({ [theme.breakpoints.down('md')]: { p: 0 } })}
            value="periodo"
          >
            {getCampaignQuery.isSuccess && (
              <SponsoredProductsFormStepSegmentation
                campaign={getCampaignQuery.data}
                updateCampaignQuery={updateCampaignQuery}
                continueAction={() => {
                  setStep('produtos');
                  router.push(`/patrocinados/editar/${id}?step=produtos`);
                }}
                cancelActionNode={
                  <Button
                    size="large"
                    onClick={() => {
                      setStep('dados');
                      router.push(`/patrocinados/editar/${id}?step=dados`);
                    }}
                    sx={{ px: 3, py: 1.5 }}
                  >
                    Voltar
                  </Button>
                }
              />
            )}

            {getCampaignQuery.isLoading && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                  mt: 4,
                }}
              >
                <StyledCampaignFormSubtitle>
                  Tempo de atuação
                </StyledCampaignFormSubtitle>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height="56px"
                />
                <StyledCampaignFormSubtitle>
                  Segmentação
                </StyledCampaignFormSubtitle>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height="56px"
                />
              </Box>
            )}
          </TabPanel>

          {/* web ou app será o valor que irá para a prop bannerType de StepBanners se ele for implementado */}
          {/* Essas condicionais servem para impedir que renderize duas vezes a tabela de produtos caso o seletor de app e web seja selecionado */}

          {isWebCampaign && isAppCampaign && renderCampaignTabPanel('web')}
          {isAppCampaign && !isAppCampaign && renderCampaignTabPanel('app')}
          {isWebCampaign && !isAppCampaign && renderCampaignTabPanel('web')}
        </TabContext>
      </StyledContainer>

      {/** TODO: Extract this as a Campaign Dialog */}
      {isPublishing && (
        <Dialog withCloseButton={false} open>
          {!updateCampaignQuery.isPending ? (
            <Typography
              sx={{ m: 0, color: 'primary.main', textAlign: 'center' }}
              variant="h4"
            >
              Você está prestes a publicar a campanha!
            </Typography>
          ) : (
            <Typography
              sx={{ m: 0, color: 'primary.main', textAlign: 'center' }}
              variant="h4"
            >
              Publicando <CircularProgress size={15} />
            </Typography>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 4, gap: 2 }}>
            <Button
              disabled={updateCampaignQuery.isPending}
              size="large"
              variant="outlined"
              sx={{ px: 3, py: 1.5 }}
              onClick={() => {
                setIsPublishing(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={updateCampaignQuery.isPending}
              size="large"
              variant="contained"
              sx={{ px: 3, py: 1.5 }}
              onClick={() => {
                updateCampaignQuery.mutate(
                  {
                    id,
                    status: 'ACTIVE',
                  },
                  {
                    onSuccess: () => {
                      setIsPublishing(false);
                      setIsPublishingSuccess(true);
                    },
                  }
                );
              }}
            >
              Publicar
            </Button>
          </Box>

          <Typography
            sx={{
              fontStyle: 'italic',
              p: 2,
              fontSize: '14px',
              textAlign: 'center',
            }}
          >
            Lembrando que você pode alterar o status da campanha a qualquer
            momento depois sem perder os dados de performance.
          </Typography>
        </Dialog>
      )}

      {isPublishingSuccess && (
        <Dialog withCloseButton={false} open>
          <Typography
            sx={{ m: 0, color: 'primary.main', textAlign: 'center' }}
            variant="h4"
          >
            Campanha publicada com sucesso!
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 4, gap: 2 }}>
            <Button
              size="large"
              variant="contained"
              sx={{ px: 3, py: 1.5 }}
              onClick={() => {
                setIsPublishingSuccess(false);
                router.push(`/patrocinados/${id}`);
              }}
            >
              Ver dashboard
            </Button>
            <Button
              size="large"
              variant="outlined"
              sx={{ px: 3, py: 1.5 }}
              onClick={() => {
                setIsPublishingSuccess(false);
              }}
            >
              Continuar editando
            </Button>
          </Box>
        </Dialog>
      )}
    </>
  );
}

'use client';

import {
  Box,
  Paper,
  Skeleton,
  styled,
  Table,
  TableCell,
  TableContainer,
  type TableContainerProps,
} from '@mui/material';

function CustomTableContainer({ component, ...props }: TableContainerProps) {
  return <TableContainer component={component ?? Box} {...props} />;
}

export const StyledContainer = styled(Paper)(({ theme }) => ({
  width: '95%',
  maxWidth: theme.breakpoints.values.xl + 300,
  margin: `0 auto`,
  padding: `${theme.spacing(5)} ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)}`,
  boxSizing: 'border-box',
  backgroundColor:
    theme.palette.mode === 'light'
      ? theme.palette.background.default
      : '#080808',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    minWidth: '100%',
    padding: `${theme.spacing(5)} ${theme.spacing(3)}`,
  },
}));

export const StyledListProducts = styled(CustomTableContainer)(({ theme }) => ({
  width: '100%',
  paddingTop: `${theme.spacing(4)}`,
}));

export const SkeletonActiveCampaings = styled(Skeleton)(({ theme }) => ({
  width: '95%',
  maxWidth: theme.breakpoints.values.xl + 300,
  margin: `0 auto`,
  padding: `${theme.spacing(5)} ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)}`,
  boxSizing: 'border-box',
  height: '1326px',
  borderRadius: '8px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    minWidth: '100%',
    padding: `${theme.spacing(5)} ${theme.spacing(3)}`,
  },
}));

export const StyledCardGridContainer = styled(Box)(({ theme }) => ({
  width: '95%',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: theme.spacing(2),
  maxWidth: theme.breakpoints.values.xl + 300,
  margin: `0 auto`,
  boxSizing: 'border-box',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

export const StyledTableContainer = styled(CustomTableContainer)(
  ({ theme }) => ({
    width: '100%',
    paddingBottom: '5px',
    backgroundColor: theme.palette.background.paper,
  })
);

export const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: theme.spacing(32),
}));

export const StyledHeaderTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.grey[800],
  borderColor: theme.palette.grey[400],
  borderWidth: 3,
}));

export const StyledHeaderBoxTableCell = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledIdTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.grey[400],
  borderColor: '#D8E0FD',
}));

export const StyledNameTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 500,
  borderColor: '#D8E0FD',
}));

import React from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, IconButton, List, ListItemIcon } from '@mui/material';

import { labelMenu } from '~/types/constants/labelMenu';
import { MenuItem } from '~/types/constants/menu';
import roles from '~/types/constants/roles';

import { StyledListItemButton, StyledListItemText } from '../elements';
import { useSubmenuState } from '../hooks/useSubmenuState';

import { SubItemMenu } from './SubItemMenu';

interface ItemMenuProps {
  item: MenuItem;
  open: boolean;
}

export function ItemMenu({ item, open }: ItemMenuProps) {
  const pathname = usePathname();
  const { data: session } = useSession();
  const role = session?.user?.role;
  const { subMenuItems, submenu, label, icon, href } = item;
  const hasSubMenu = subMenuItems && subMenuItems.length > 0;
  const { toggleSubmenu, selectedSubmenu, clearSelectedSubmenu } =
    useSubmenuState();
  const isSubmenuOpen =
    submenu && subMenuItems?.some(sub => pathname.includes(sub.href));

  // function handleNavClick() {
  //   if (isMobile || window.innerWidth < 600) {
  //      toggleMenu();
  //   }
  // }

  if (label === labelMenu.users) {
    if (role === roles[0] || role === roles[2]) {
      return null;
    }
  }

  return (
    <Box key={label}>
      <Link href={href} legacyBehavior>
        <StyledListItemButton
          className={
            pathname.includes(href) || selectedSubmenu === 'Patrocinados'
              ? 'active'
              : ''
          }
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              width: '100%',
            }}
          >
            {icon && (
              <ListItemIcon
                sx={{
                  minWidth: 'inherit',
                  marginTop: '-3px',
                  color: 'currentcolor',
                }}
              >
                {icon}
              </ListItemIcon>
            )}

            <StyledListItemText open={open} inset={!icon}>
              {label}
            </StyledListItemText>
          </Box>
          {hasSubMenu && open && (
            <IconButton
              onClick={e => {
                e.stopPropagation();
                toggleSubmenu(label);
              }}
              sx={{
                marginLeft: 'auto',
                color: 'inherit',
                padding: 0,
              }}
            >
              {isSubmenuOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
        </StyledListItemButton>
      </Link>
      {hasSubMenu && (
        <Collapse in={isSubmenuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subMenuItems.map(subItem => (
              <SubItemMenu
                key={subItem.href}
                subItem={subItem}
                open={open}
                pathname={pathname}
                selectedSubmenu={selectedSubmenu}
                clearSelectedSubmenu={clearSelectedSubmenu}
                toggleSubmenu={toggleSubmenu}
              />
            ))}
          </List>
        </Collapse>
      )}
    </Box>
  );
}

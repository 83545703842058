import { useQuery, useQueryClient } from '@tanstack/react-query';

import { serviceApi } from '~/features';
import { clearParams, clearParamsSort, parseDate } from '~/shared';
import type { CampaignListModelView } from '~/types';
import { ProductListDTO } from '~/types/product/list.dto';
import { Product } from '~/types/product/product';

export const GET_SPONSORED_PRODUCTS_KEY = 'GET_SPONSORED_PRODUCTS_QUERY';

async function getSponsoredProducts(params: ProductListDTO) {
  let clearedParams;
  if (
    !params.orderCampaign ||
    !params.orderDevice ||
    !params.orderDevice ||
    !params.orderInitial ||
    !params.orderFinish
  ) {
    clearedParams = clearParamsSort(params);
  } else {
    clearedParams = clearParams(params);
  }

  const response = serviceApi.get<CampaignListModelView>(
    `/CampanhaProdutosPatrocinados/List`,
    {
      params: clearedParams,
    }
  );

  return response;
}

export function useGetSponsoredProducts(params: ProductListDTO) {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [GET_SPONSORED_PRODUCTS_KEY, params],
    select: data => {
      const returnData = data?.data;

      return {
        ...returnData,
        list: returnData?.list.map(item => {
          return {
            ...item,
            startDate: parseDate(item.startDate),
            endDate: parseDate(item.endDate),
            createdAt: parseDate(item.createdAt),
            updatedAt: parseDate(item.updatedAt),
            deletedAt: parseDate(item.deletedAt),
          };
        }),
      };
    },
    queryFn: () => getSponsoredProducts(params),
  });

  return Object.assign(query, {
    refetchAll: () =>
      queryClient.refetchQueries({ queryKey: [GET_SPONSORED_PRODUCTS_KEY] }),
  });
}

export type UseGetSponsoredProductsReturn = ReturnType<
  typeof useGetSponsoredProducts
>['data'];

export type UseGetSponsoredProductsReturnList = Array<
  Omit<
    Product,
    'startDate' | 'endDate' | 'createdAt' | 'updatedAt' | 'deletedAt'
  > & {
    startDate: Date | null;
    endDate: Date | null;
    createdAt: Date | null;
    updatedAt: Date | null;
    deletedAt: Date | null;
  }
>;

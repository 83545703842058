import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/ArrowDownward.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/ArrowUpward.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/ArrowBack.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/ArrowForward.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/BorderColorOutlined.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/CheckBox.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/CheckBoxOutlineBlank.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/Close.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/DarkMode.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/KeyboardArrowDown.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/LightMode.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/LocalOfferOutlined.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/MouseOutlined.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/RemoveRedEyeOutlined.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/TrendingDown.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/TrendingUp.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/TrendingUpOutlined.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/Group.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Autocomplete/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Box/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Button/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Checkbox/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/CircularProgress/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Dialog/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/DialogContent/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/DialogTitle/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/IconButton/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Pagination/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Skeleton/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/TableBody/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/TableCell/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/TableHead/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/TableRow/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/TextField/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Typography/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useMutation.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useQueries.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useQuery.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/features/Themes/variations/common.tsx\",\"import\":\"Rubik\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"roboto\"}");
import(/* webpackMode: "eager" */ "/app/src/entities/Advertiser/components/AdvertisersSelect.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Advertiser/components/elements.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Advertiser/components/List/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Advertiser/dialogs/Create.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Advertiser/dialogs/Delete.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Advertiser/dialogs/Update.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Banners/components/List.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Breadcrumbs/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Campaign/components/CreateCampaignButton.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Campaign/components/elements.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Campaign/components/StatusSelect.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Campaign/components/StatusSelectBadge.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Campaign/dialogs/EditImage.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Campaign/forms/StepBanners/BannerManager.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Campaign/forms/StepBanners/StepBanners.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Campaign/forms/StepCreation.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Campaign/forms/StepInformation.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Campaign/forms/StepSegmentation/Step.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Login/components/elements.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Login/components/LoginForm.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Reports/components/AdvertisersList/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Reports/components/BannerList/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Reports/components/CampaignList/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/Reports/components/CampaignsList/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/SponsoredProducts/components/CreateSponsoredButton.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/SponsoredProducts/forms/SponsoredProductsFormStepCreation.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/SponsoredProducts/forms/SponsoredProductsFormStepInformation.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/SponsoredProducts/forms/StepSegmentation/Step.tsx");
import(/* webpackMode: "eager" */ "/app/src/entities/User/components/UserContextMenu/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/features/Auth/components/MartinsTokenRefreshSession.tsx");
import(/* webpackMode: "eager" */ "/app/src/features/Auth/hooks/useUserNonce.tsx");
import(/* webpackMode: "eager" */ "/app/src/features/Auth/provider.tsx");
import(/* webpackMode: "eager" */ "/app/src/features/QueryClient/provider.tsx");
import(/* webpackMode: "eager" */ "/app/src/features/Themes/global.scss");
import(/* webpackMode: "eager" */ "/app/src/features/Themes/lib/context.tsx");
import(/* webpackMode: "eager" */ "/app/src/features/Themes/lib/provider.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/Card/elements.ts");
import(/* webpackMode: "eager" */ "/app/src/shared/components/ContentTitle/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/CustomTextField/Currency.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/ImageCropper/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/Pagination/elements.ts");
import(/* webpackMode: "eager" */ "/app/src/shared/components/PlatformSelect/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/ProcessedName/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/SavingStatusBadge/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/SearchBox/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/StatusBadge/elements.ts");
import(/* webpackMode: "eager" */ "/app/src/shared/components/Switches/DefaultSwitch.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/Table/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/WeekSelect/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/anunciantes/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/campanha/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/campanhas-ativas/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/campanhas/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/dashboard/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/form-campanha/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/form-patrocinados/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/layout/Main.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/login/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/nova-campanha-produtos/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/nova-campanha/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/relatorios-banner/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/relatorios-campanha/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/relatorios-campanhas/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/relatorios/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/starter/index.tsx")
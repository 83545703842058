'use client';

import { useEffect, useRef, useState } from 'react';
import { useSession } from 'next-auth/react';
import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControlLabel,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  StyledCampaignFormLabelTitle,
  StyledCampaignFormRow,
  useBreadcrumbs,
} from '~/entities';
import { ActiveCampaignRow } from '~/entities/ActivesCampaigns';
import {
  ContentTitle,
  DefaultSwitch,
  StyledContainer,
  StyledHeaderTableCell,
  StyledTable,
  StyledTableContainer,
} from '~/shared';

import { LoadingActivesCampaignsView } from './loading';

export interface Banner {
  campaignName: string;
  isWebCampaign: boolean;
  isAppCampaign: boolean;
  startDate: string;
  endDate: string;
  bannerImage: string;
  bannerLink: string;
  bannerValueInvestment: number;
  valueTotalInvestment: number;
  campaignId: string;
  advertiserName: string;
}
interface ActiveCampaignProps {
  formatName: string;
  keyName: string;
  banners: Banner[];
}

export function ActiveCampaignsView() {
  const breadcrumbs = useBreadcrumbs();

  const [isLoading, setIsLoading] = useState(true);

  const [camp, setCamp] = useState<ActiveCampaignProps[]>([]);

  const [campaignActive, setCampaignActive] = useState<
    { id: number; active: boolean }[]
  >([]);

  const session = useSession();
  const token = session?.data?.t.st;

  const hasInitialized = useRef(false);
  useEffect(() => {
    if (!hasInitialized.current) {
      breadcrumbs.setPaths([
        { label: 'Campanhas Ativas', path: '/campanhas-ativas' },
      ]);
      hasInitialized.current = true;
    }
  }, [breadcrumbs]);

  useEffect(() => {
    function getCampaign() {
      fetch(
        `${process.env.NEXT_PUBLIC_SERVICE_API_URL}/api/v1/Campanha/ActiveCampaigns`,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then(response => response.json())
        .then(actives => {
          setCamp(actives);
          setCampaignActive(
            Array.from({ length: actives.length }, (_, index) => ({
              id: index,
              active: false,
            }))
          );
          setIsLoading(false);
        });
    }

    getCampaign();
  }, []);

  const sortedCamp = camp.sort((a, b) => {
    const hasBannersA = a.banners.length > 0;
    const hasBannersB = b.banners.length > 0;

    if (hasBannersA && !hasBannersB) {
      return -1;
    }

    if (!hasBannersA && hasBannersB) {
      return 1;
    }

    return 0;
  });

  return (
    <>
      <ContentTitle
        title="Campanhas Ativas"
        actions={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            onClick={() =>
              window.open(
                `${process.env.NEXT_PUBLIC_SERVICE_API_URL}/api/v1/Campanha/ActiveCampaigns-Export`,
                '_blank'
              )
            }
            sx={theme => ({
              textTransform: 'none',
              py: theme.spacing(1),
              px: theme.spacing(2),
              [theme.breakpoints.down('lg')]: {
                py: theme.spacing(3),
                fontSize: theme.typography.pxToRem(18),
                width: '90%',
                mx: 'auto',
              },
            })}
            startIcon={<Add />}
          >
            Baixar relatório
          </Button>
        }
        isFetching={isLoading}
      />
      {!isLoading ? (
        <StyledContainer>
          {sortedCamp.map((campaign, index) => (
            <Box key={campaign?.formatName} sx={{ marginBottom: 3 }}>
              <StyledCampaignFormRow key={campaign?.formatName}>
                <FormControlLabel
                  control={<DefaultSwitch />}
                  label={
                    <StyledCampaignFormLabelTitle sx={{ display: 'flex' }}>
                      {`${campaign?.formatName}`}
                      {campaign.banners.length < 1 && (
                        <Typography sx={{ color: 'red' }}>
                          (Não há campanhas)
                        </Typography>
                      )}
                    </StyledCampaignFormLabelTitle>
                  }
                  checked={campaignActive[index]?.active}
                  onChange={(_, checked) =>
                    setCampaignActive(prev =>
                      prev.map(item =>
                        item.id === index ? { ...item, active: checked } : item
                      )
                    )
                  }
                />
              </StyledCampaignFormRow>
              {campaignActive[index]?.active && (
                <StyledTableContainer>
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        <StyledHeaderTableCell
                          sx={{ width: '60px' }}
                          align="center"
                        >
                          ID
                        </StyledHeaderTableCell>
                        <StyledHeaderTableCell
                          sx={{
                            minWidth: '200px',
                            position: 'sticky',
                            left: 0,
                            backgroundColor: 'background.paper',
                          }}
                        >
                          Anunciante / Campanha
                        </StyledHeaderTableCell>
                        <StyledHeaderTableCell align="center">
                          Plataforma
                        </StyledHeaderTableCell>
                        <StyledHeaderTableCell align="center">
                          Investimento
                        </StyledHeaderTableCell>
                        <StyledHeaderTableCell align="center">
                          Início
                        </StyledHeaderTableCell>
                        <StyledHeaderTableCell align="center">
                          Término
                        </StyledHeaderTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {campaign?.banners?.length === 0 &&
                        !campaign.banners[0]?.campaignId && (
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              Sem Banners
                            </TableCell>
                          </TableRow>
                        )}
                      {campaign?.banners.map(banner => (
                        <ActiveCampaignRow
                          key={banner.campaignId}
                          banner={banner}
                        />
                      ))}
                    </TableBody>
                  </StyledTable>
                </StyledTableContainer>
              )}
            </Box>
          ))}
        </StyledContainer>
      ) : (
        <LoadingActivesCampaignsView />
      )}
    </>
  );
}

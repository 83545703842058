import { useRouter } from 'next/navigation';
import { TableCell, TableRow } from '@mui/material';

import {
  PlatformBadge,
  ProcessedDate,
  ProcessedName,
  StatusBadge,
  StyledIdTableCell,
} from '~/shared';

import { UseGetSponsoredProductsReturnList } from '../../hooks/useGetSponsoredProducts';

export type ProductRowProps = {
  product: UseGetSponsoredProductsReturnList[number];
};

export function SponsoredProductRow({ product }: ProductRowProps) {
  const router = useRouter();
  return (
    <TableRow
      key={product.name}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          '.MuiTableCell-body': {
            backgroundColor: theme => theme.palette.grey[100],
          },
        },
        '.MuiTableCell-body': {
          backgroundColor: 'background.paper',
        },
      }}
    >
      <StyledIdTableCell
        component="th"
        scope="row"
        align="center"
        onClick={() => router.push(`/patrocinados/${product.id}`)}
      >
        {product.id}
      </StyledIdTableCell>
      <TableCell
        component="th"
        scope="row"
        sx={{
          position: 'sticky',
          left: 0,
        }}
        onClick={() => router.push(`/patrocinados/${product.id}`)}
      >
        <ProcessedName
          name={product.name}
          advertiserName={product.advertiser?.name}
        />
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        onClick={() => router.push(`/patrocinados/${product.id}`)}
      >
        <PlatformBadge isApp={product.isApp} isWeb={product.isWeb} />
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        align="center"
        onClick={() => router.push(`/patrocinados/${product.id}`)}
      >
        {product.startDate ? <ProcessedDate date={product.startDate} /> : '-'}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        align="center"
        onClick={() => router.push(`/patrocinados/${product.id}`)}
      >
        {product.endDate ? <ProcessedDate date={product.endDate} /> : '-'}
      </TableCell>

      <TableCell
        component="th"
        scope="row"
        align="center"
        onClick={() => router.push(`/patrocinados/${product.id}`)}
      >
        <StatusBadge status={product.status} />
      </TableCell>
    </TableRow>
  );
}

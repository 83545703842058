import { CustomIconsProps } from './types';

export function DownloadIcon({
  size = 24,
  color = 'currentColor',
}: CustomIconsProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 10.5L2.625 6.125L3.85 4.85625L6.125 7.13125V0H7.875V7.13125L10.15 4.85625L11.375 6.125L7 10.5ZM0 14V9.625H1.75V12.25H12.25V9.625H14V14H0Z"
        fill={color}
      />
    </svg>
  );
}

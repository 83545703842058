/* eslint-disable no-console */

'use client';

import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import {
  ProductCreatedModal,
  SponsoredProductsFormStepCreation,
  StyledTabItem,
  useBreadcrumbs,
  useCreateSponsoredProduct,
} from '~/entities';
import { ContentTitle, StyledContainer } from '~/shared';
import type { CreateCampaignDto } from '~/types';
import roles from '~/types/constants/roles';

export function NovaCampanhaProdutosView() {
  const breadcrumbs = useBreadcrumbs();
  const router = useRouter();
  const session = useSession();
  const role = session?.data?.user?.role;

  const [isAppCampaign, setIsAppCampaign] = useState(false);
  const [isWebCampaign, setIsWebCampaign] = useState(false);
  const [isCampaignCreated, setIsCampaignCreated] = useState<string | null>(
    null
  );

  const createCampaignMutation = useCreateSponsoredProduct(data => {
    setIsCampaignCreated(data.data.id);
  });
  // const createCampaignMutation = useCreateCampaign(data => {
  //   setIsCampaignCreated(data.data.id);
  // });

  function handleCreationStepContinue(createObject: CreateCampaignDto) {
    createCampaignMutation.mutate(createObject);
  }

  useEffect(() => {
    if (role === roles[0]) router.push('/patrocinados');
  }, [role, router]);

  useEffect(() => {
    breadcrumbs.setPaths([
      { label: 'Produtos Patrocinados', path: '/patrocinados' },
      {
        label: 'Nova Campanha de produtos patrocinados',
        path: '/patrocinados/nova',
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContentTitle title="Nova Campanha de produtos patrocinados" />

      <StyledContainer>
        <TabContext value="1">
          <TabList variant="scrollable" scrollButtons allowScrollButtonsMobile>
            <StyledTabItem label="Dados de campanha" value="1" />
            <StyledTabItem label="Período e Segmentação" disabled />
            <StyledTabItem label="Lista de Produtos" disabled />
          </TabList>

          <TabPanel value="1">
            <SponsoredProductsFormStepCreation
              isWebCampaign={isWebCampaign}
              setIsWebCampaign={setIsWebCampaign}
              isAppCampaign={isAppCampaign}
              setIsAppCampaign={setIsAppCampaign}
              continueAction={handleCreationStepContinue}
              isLoadingAction={createCampaignMutation.isPending}
            />
          </TabPanel>
        </TabContext>
      </StyledContainer>

      {isCampaignCreated && <ProductCreatedModal id={isCampaignCreated} />}
    </>
  );
}

'use client';

import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useState,
} from 'react';
import { useDebounce } from 'react-use';
import { useRouter } from 'next/navigation';
import {
  Box,
  Button,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';

import { CurrencyTextField, DefaultSwitch } from '~/shared';

import {
  StyledCampaignForm,
  StyledCampaignFormLabelTitle,
} from '../../Campaign/components/elements';
import { useGetCampaign, useUpdateCampaign } from '../../Campaign/hooks';

export function SponsoredProductsFormStepInformation({
  campaignId,
  campaign,
  getCampaignQuery,
  updateCampaignQuery,
  setStepOneFormError,
  setIsAppCampaign,
  setIsWebCampaign,
  continueAction,
  cancelActionNode,
}: {
  campaignId: string;
  campaign: ReturnType<typeof useGetCampaign>['data'];
  getCampaignQuery: ReturnType<typeof useGetCampaign>;
  updateCampaignQuery: ReturnType<typeof useUpdateCampaign>;
  setStepOneFormError: Dispatch<SetStateAction<boolean>>;
  setIsAppCampaign: Dispatch<SetStateAction<boolean>>;
  setIsWebCampaign: Dispatch<SetStateAction<boolean>>;
  continueAction: () => void;
  cancelActionNode: ReactNode;
}) {
  const router = useRouter();

  const [campaignName, setCampaignName] = useState(campaign?.name);
  const [campaignNameError, setCampaignNameError] = useState<string | null>(
    null
  );

  const [campaignValue, setCampaignValue] = useState<number | undefined>(
    campaign?.totalInvestment
  );
  const [campaignValueError, setCampaignValueError] = useState<string | null>(
    null
  );

  const [isApp, setIsApp] = useState<boolean>(!!campaign?.isApp);
  const [isWeb, setIsWeb] = useState<boolean>(!!campaign?.isWeb);

  const [campaignTypeError, setCampaignTypeError] = useState<string | null>(
    null
  );

  function handleFormCheck() {
    let hasError = false;

    if (!campaignName) {
      setCampaignNameError('Informe o nome da campanha');
      hasError = true;
    } else {
      setCampaignNameError(null);
    }

    if (!campaignValue) {
      setCampaignValueError('Informe o valor da campanha');
      hasError = true;
    } else {
      setCampaignValueError(null);
    }

    if (!isWeb && !isApp) {
      setCampaignTypeError('Selecione ao menos um tipo de campanha');

      hasError = true;
    } else {
      setCampaignTypeError(null);
    }

    setStepOneFormError(hasError);

    return !hasError;
  }

  function handleContinue() {
    if (handleFormCheck()) continueAction();
  }

  useDebounce(
    () => {
      handleFormCheck();

      if (campaignName === '') return;

      if (campaignName !== campaign?.name) {
        updateCampaignQuery.mutate({
          id: campaignId,
          name: campaignName,
        });
      }

      if (campaignValue !== campaign?.totalInvestment) {
        updateCampaignQuery.mutate({
          id: campaignId,
          totalInvestment: campaignValue ?? 0,
        });
      }

      if (isWeb !== campaign?.isWeb) {
        updateCampaignQuery.mutate({
          id: campaignId,
          isWeb,
        });
      }

      if (isApp !== campaign?.isApp) {
        updateCampaignQuery.mutate({
          id: campaignId,
          isApp,
        });
      }
    },
    500,
    [campaignId, campaignName, campaignValue, isApp, isWeb]
  );

  return (
    <StyledCampaignForm>
      <TextField
        disabled={getCampaignQuery.isLoading}
        value={campaignName}
        onChange={event => {
          setCampaignNameError(null);
          setCampaignName(event.target.value);
        }}
        id="campaign-name"
        label="Nome da Campanha"
        variant="filled"
        error={!!campaignNameError}
        helperText={campaignNameError}
      />

      <TextField
        disabled
        value={campaign?.advertiser?.name ?? ''}
        id="advertisers-name"
        label="Anunciante"
        variant="filled"
      />

      <CurrencyTextField
        disabled={getCampaignQuery.isLoading}
        id="campaign-value"
        label="Valor da campanha"
        variant="filled"
        value={campaignValue}
        onChange={event => {
          setCampaignValueError(null);
          setCampaignValue(Number(event.target.value));
        }}
        error={!!campaignValueError}
        helperText={campaignValueError}
      />

      <Box
        sx={{
          display: 'flex',
          gap: 2,
          padding: 2,
          border: campaignTypeError ? '1px solid red' : '1px solid transparent',
        }}
      >
        <FormControlLabel
          checked={isWeb}
          onChange={(_, newValue) => {
            setCampaignTypeError(null);
            setIsWebCampaign(newValue);
            setIsWeb(newValue);
          }}
          control={<DefaultSwitch />}
          label={
            <StyledCampaignFormLabelTitle>
              Campanha Web
            </StyledCampaignFormLabelTitle>
          }
        />
        <FormControlLabel
          checked={isApp}
          onChange={(_, newValue) => {
            setCampaignTypeError(null);
            setIsAppCampaign(newValue);
            setIsApp(newValue);
          }}
          control={<DefaultSwitch />}
          label={
            <StyledCampaignFormLabelTitle>
              Campanha App
            </StyledCampaignFormLabelTitle>
          }
        />
        {campaignTypeError && (
          <>
            <Box sx={{ flexGrow: 1 }} />
            <Typography
              sx={{ color: theme => theme.palette.error.main, mt: 1 }}
            >
              {campaignTypeError}
            </Typography>
          </>
        )}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        {cancelActionNode}

        <Box sx={{ display: 'flex', gap: 2 }}>
          {campaign?.status === 'DRAFT' && (
            <Button
              variant="outlined"
              color="primary"
              size="large"
              sx={{ px: 3, py: 1.5 }}
              onClick={() => {
                if (!campaign) return;
                updateCampaignQuery.mutate({
                  id: campaign.id,
                  status: 'DRAFT',
                });
                router.push(`/campanhas/${campaign.id}`);
              }}
            >
              Salvar Rascunho
            </Button>
          )}
          <Button
            size="large"
            sx={{ px: 3, py: 1.5 }}
            variant="contained"
            onClick={() => handleContinue()}
          >
            Continuar
          </Button>
        </Box>
      </Box>
    </StyledCampaignForm>
  );
}

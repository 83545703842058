import { useState } from 'react';

import { labelMenu } from '~/types/constants/labelMenu';

export function useSubmenuState() {
  const [openSubmenus, setOpenSubmenus] = useState<string[]>([]);
  const [selectedSubmenu, setSelectedSubmenu] = useState<string | null>(null);

  function toggleSubmenu(label: string) {
    setOpenSubmenus(prev => {
      if (
        label === labelMenu.inventory &&
        !prev.includes(labelMenu.inventory)
      ) {
        return [...prev, labelMenu.inventory, 'Banners'];
      }
      if (label === labelMenu.inventory && prev.includes(labelMenu.inventory)) {
        return prev.filter(
          item => item !== labelMenu.inventory && item !== 'Banners'
        );
      }
      return prev.includes(label)
        ? prev.filter(item => item !== label)
        : [...prev, label];
    });

    setSelectedSubmenu(prev => (prev === label ? null : label));
  }

  function clearSelectedSubmenu() {
    setSelectedSubmenu(null);
  }

  return { openSubmenus, toggleSubmenu, selectedSubmenu, clearSelectedSubmenu };
}

import { useQuery } from '@tanstack/react-query';

import { serviceApi } from '~/features';
import { parseDate } from '~/shared';
import { type CampaignModelView } from '~/types';

export const GET_SPONSORED_PRODUCT_CAMPAIGN_QUERY_KEY =
  'GET_SPONSORED_PRODUCT_CAMPAIGN_QUERY_KEY';

export async function getProduct(id: string) {
  return serviceApi.get<CampaignModelView>(
    `/CampanhaProdutosPatrocinados?id=${id}`
  );
}

export function useGetProduct(id: string) {
  return useQuery({
    queryKey: [GET_SPONSORED_PRODUCT_CAMPAIGN_QUERY_KEY, id],
    select: data => {
      const returnData = data?.data;

      const startDate = parseDate(returnData?.startDate);
      const endDate = parseDate(returnData?.endDate);
      const createdAt = parseDate(returnData?.createdAt);
      const updatedAt = parseDate(returnData?.updatedAt);
      const deletedAt = parseDate(returnData?.deletedAt);

      if (returnData?.dayOfWeek) {
        returnData.dayOfWeek = data?.data?.dayOfWeek.map(day => String(day));
      }

      return {
        ...returnData,
        startDate,
        endDate,
        createdAt,
        updatedAt,
        deletedAt,
      };
    },
    queryFn: () => getProduct(id),
  });
}

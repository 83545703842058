const labelMenu = {
  dashboard: 'Dashboard',

  inventory: 'Inventário',
  advertisers: 'Anunciantes',
  reports: 'Relatórios',
  users: 'Usuários',
};

const submenu = {
  banners: 'Banners',
  campaign: 'Campanhas',
  sponsored: 'Patrocinados',
  actives: 'Ativos',
};

export { labelMenu, submenu };
